<template>
  <div style="width:160px; height:192px;">
    <window-header :minimize="false"></window-header>
    <img style="width:100%;" :src="articulo.thumbnail&&articulo.thumbnail.imagen" />
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
export default {
  mixins: [WindowMixin],
  data: function() {
    return {
      title: "Imagen",
      articulo: this.winOptions.articulo
    };
  }
};
</script>
